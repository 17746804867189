import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/Layout"
import Hero from "../../components/Hero"
import Bio from "./components/bio"
import Share from "../../components/Share"
import Contact from "./components/contact"

const About = props => {
  const query = useStaticQuery(graphql`
    query {
      contentfulPageData(slug: { eq: "about" }) {
        title
        subtitle
        pageHeader {
          file {
            url
          }
          title
        }
        thumbnail {
          title
          file {
            url
          }
        }
        mainContent {
          mainContent
        }
        supportContent {
          supportContent
        }
      }
    }
  `)

  const { contentfulPageData: data } = query
  return (
    <Layout>
      <Hero heroImage={data.pageHeader} title={"Me"} />
      <Bio
        title={data.title}
        subtitle={data.subtitle}
        mainContent={data.mainContent.mainContent}
        supportContent={data.supportContent.supportContent}
        bioPic={data.thumbnail}
      />
      <Contact />
      <Share />
    </Layout>
  )
}

export default About
