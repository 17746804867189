import React from "react"
import PropTypes from "prop-types"

const Bio = ({ bioPic, mainContent, subtitle, supportContent, title }) => {
  return (
    <section className="image-square left bg-secondary pb-sm-64">
      <div className="col-md-6 image">
        <div className="background-image-holder">
          <div id="particles"></div>
          <img
            alt="{bioPic.title}"
            className="background-image"
            src={
              "//images.ctfassets.net/me6z7bfxw2qk/1TSX2aTbnBX27vIR1iz6lM/6d4977e654138f997c6492a64cb598ab/resto5.jpg"
            }
          />
        </div>
      </div>
      <div className="col-md-6 col-md-offset-1 content">
        <h3 className="bold mt104">{title}</h3>
        <p className="mb0 leader dark-text bold-h6 pb16">{subtitle}</p>
        <p className="mb0 pt2 pb16">{mainContent}</p>
        <p className="leader dark-text mt-4">{supportContent}</p>
      </div>
    </section>
  )
}

Bio.propTypes = {
  bioPic: PropTypes.object,
  mainContent: PropTypes.string,
  subtitle: PropTypes.string,
  supportContent: PropTypes.string,
  title: PropTypes.string,
}

export default Bio
